import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/scss/app.scss";
import VueToastr2 from "vue-toastr-2";
import "vue-toastr-2/dist/vue-toastr-2.min.css";

window.toastr = require("toastr");

Vue.use(VueToastr2);

window.toastr.options.closeButton = true;
window.toastr.options.closeDuration = 300;

require("./plugins/fontawesome");

const VueFormulate = require("@braid/vue-formulate");
Vue.use(VueFormulate.default, {
  classes: {
    outer: "mb-4",
    input:
      "border border-gray-400 rounded px-3 py-2 leading-none focus:border-green-500 outline-none border-box w-full mb-1",
    label: "font-medium text-left capitalize text-gray-500",
    help: "text-xs mb-1 text-gray-600",
    error: "text-red-700 text-xs mb-1"
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
