import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    name: "home",
    component: loadView("Home")
  },
  {
    path: "/alcances",
    name: "alcances",
    component: loadView("Alcances")
  },
  {
    path: "/servicios",
    name: "servicios",
    component: loadView("Servicios")
  },
  {
    path: "/proyectos",
    name: "proyectos",
    component: loadView("Proyectos")
  },
  {
    path: "/presupuesto",
    name: "presupuesto",
    component: loadView("Presupuesto")
  },
  {
    path: "/contacto",
    name: "contacto",
    component: loadView("Contacto")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
