<template>
  <div id="app">
    <nav-bar id="nav"></nav-bar>
    <router-view />
    <vue-footer />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import vueFooter from "@/components/Footer";

export default {
  name: "main",
  components: {
    "nav-bar": NavBar,
    "vue-footer": vueFooter
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 0.25rem;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #2c3e50;
    }
  }
}
</style>
