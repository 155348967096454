import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faBars,
  faChevronRight,
  faChevronCircleUp,
  faPencilRuler,
  faBuilding,
  faChalkboardTeacher,
  faEnvelope,
  faPhoneAlt,
  faMapMarkedAlt,
  faHouseUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faHome, faBars, faChevronRight, faChevronCircleUp, faPencilRuler, faBuilding, faChalkboardTeacher, faPhoneAlt, faEnvelope, faMapMarkedAlt, faHouseUser);

Vue.component("font-awesome-icon", FontAwesomeIcon);
