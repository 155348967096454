<template>
  <nav
    class="fixed z-30 flex flex-wrap items-center justify-between w-full px-2 shadow-lg navbar-expand-lg"
  >
    <div
      class="container flex flex-wrap items-center justify-between px-4 mx-auto"
    >
    <div class="w-full pr-3 text-sm text-right sm:pr-4 font">
      <div class="inline p-2 underline"><a href="/">Español</a></div>
      <div class="inline p-2"><a href="/en">English</a></div>
    </div>
      <div
        class="relative flex justify-between w-full px-4 lg:w-auto lg:static lg:block lg:justify-start"
      >
        <router-link
          to="/"
          class="inline-block mr-4 leading-relaxed whitespace-no-wrap no-activo"
        >
          <h1 class="logo-text">
            fernando daniel lopez
          </h1>
          <div class="sublogo">
            arquitecto
          </div>
        </router-link>
        <button
          class="block px-3 py-1 text-xl text-2xl leading-none text-left text-gray-600 bg-transparent border border-transparent border-solid rounded outline-none cursor-pointer lg:hidden focus:outline-none"
          type="button"
          v-on:click="toggleNavbar()"
        >
          <font-awesome-icon icon="bars" />
        </button>
      </div>
      <div
        v-bind:class="{ hidden: !showMenu, flex: showMenu }"
        class="items-center lg:flex lg:flex-grow"
      >
        <ul class="flex flex-col ml-auto list-none lg:flex-row">
          <li class="nav-item">
            <span class="item-nav" @click="toggleNavbar">
              <router-link to="/" class="item-nav-link">inicio </router-link>
            </span>
          </li>
          <li class="nav-item">
            <span class="item-nav" @click="toggleNavbar">
              <router-link to="/alcances" class="item-nav-link"
                >alcances
              </router-link>
            </span>
          </li>
          <li class="nav-item">
            <span class="item-nav" @click="toggleNavbar">
              <router-link to="/servicios" class="item-nav-link"
                >servicios
              </router-link>
            </span>
          </li>
          <li class="nav-item">
            <span class="item-nav" @click="toggleNavbar">
              <router-link to="/proyectos" class="item-nav-link"
                >proyectos/obras
              </router-link>
            </span>
          </li>
          <li class="nav-item">
            <span class="item-nav" @click="toggleNavbar">
              <router-link to="/presupuesto" class="item-nav-link"
              >presupuesto
              </router-link>
            </span>
          </li>
          <li class="nav-item">
            <span class="item-nav" @click="toggleNavbar">
              <router-link to="/contacto" class="item-nav-link" 
                >contacto
              </router-link>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      showMenu: false
    };
  },
  methods: {
    toggleNavbar: function() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>

<style lang="scss" scoped>
$color-active: #2c3e50;

.item-nav {
  a.router-link-exact-active {
    color: $color-active;
    font-weight: bolder;
    border-bottom: 2px solid $color-active;
  }

  a.no-activo {
    background: none !important;
    border-bottom: none;
  }
}
</style>
