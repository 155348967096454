<template>

  <div class="container mx-auto mt-16">
    <div class="flex flex-row mx-auto">

      <div class="m-auto">
      <a @click="scrollToTop" class="text-4xl text-gray-500 cursor-pointer hover:text-gray-600 text-opacity-30" title="ir arriba">
        <font-awesome-icon icon="chevron-circle-up" /> <br>
      </a>
      </div>

    </div>
  <div
    class="flex flex-row w-1/2 h-16 mx-auto border-t-2 border-b-2 border-solid"
  >



    <div class="m-auto text-gray-500 font-oxygen">
      website Fernando D. Lopez - Arquitecto
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    }
  }
};
</script>
